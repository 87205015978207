<template>
<loader v-if="showloader==true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Recherche"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_offre"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table
            class="table table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
               class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-uppercase"
              >
                <th>#</th>
                <th>Nom <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i></th>
                <!-- <th class="col-md-4" v-for="l in ListeLangue" :key="l.id">
                    Nom ({{l.code}})
                </th> -->
                <th>Max connection <i class="fa fa-sort" @click="sortNumerique('maxConnexions')" style="cursor:pointer !important;"></i></th>
                <th>Max Module <i class="fa fa-sort" @click="sortNumerique('maxModules')" style="cursor:pointer !important;"></i></th>
                <th>Max Sequence <i class="fa fa-sort" @click="sortNumerique('maxSequences')" style="cursor:pointer !important;"></i></th>
                <th>Max Qestion <i class="fa fa-sort" @click="sortNumerique('MaxQuestions')" style="cursor:pointer !important;"></i></th>
                <th>Prix <i class="fa fa-sort" @click="sortNumerique('price')" style="cursor:pointer !important;"></i></th>
                <th>Mode <i class="fa fa-sort" @click="sortNumerique('mode')" style="cursor:pointer !important;"></i></th>
                <th>Abonnement <i class="fa fa-sort" @click="sortNumerique('abonnement')" style="cursor:pointer !important;"></i></th>
                <th>Module</th>
                <th>Quizz</th>
                <th>date <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>
                <th colspan="2">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="item.id">
                <td class="odd">{{ index + 1 }}</td>
                <td class="odd">{{ item.name }}</td>
                <!-- <td class="col-md-4" v-for="l in item.traduction" :key="l.id">
                    <p>{{l.value}} ({{l.langue[0].code}})</p>
                </td> -->
                <td class="odd">{{ item.maxConnexions }}</td>
                <td class="odd">{{ item.maxModules }}</td>
                <td class="odd">{{ item.maxSequences }}</td>
                <td class="odd">{{ item.MaxQuestions }}</td>
                <td class="odd">Rs {{ item.price }} </td>
                <td
                  v-if="item.mode == 0"
                  style="color: red; font-weight: bold"
                  class="odd"
                >
                  Gratuit
                </td>
                <td
                  class="odd"
                  v-if="item.mode == 1"
                  style="color: #50cd89; font-weight: bold"
                >
                  Payant
                </td>
                <td class="odd">{{ item.abonnement }} mois</td>

                <td class="odd" v-if="item.modules.length > 0">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_Listemodules"
                    @click="listeModule(item.modules)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    Modules
                  </span>
                </td>
                <td v-else>&nbsp;</td>

                <td class="odd" v-if="item.offrequizz.length > 0">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_Listequizz"
                    @click="listeQuizzShow(item.offrequizz)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    Quizz
                  </span>
                </td>
                <td v-else>&nbsp;</td>


                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td class="odd" v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="end">
                  <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_offre"
                    ></i
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="kt_modal_offre">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formMode }} Offre</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>
        <!--Start Body modal add user-->
        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="name" class="form-label required">Nom</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6"></div>
            </div>
          <!-- <div class="row"  v-for="(langueName,index) in add_data.listeName" :key="index">
            
              <div class="col-md-12 padding_left_none">
                <label for="name" class="form-label required">NOM ({{langueName.name}})</label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  class="form-control m-2"
                  v-model="langueName.valueN"
                />
              </div>
           
          </div> -->
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="maxConnexions" class="form-label"
                  >max Connexions
                </label>
                <input
                  type="number"
                  id="maxConnexions"
                  name="maxConnexions"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.maxConnexions"
                />
              </div>
              <div class="col-md-6">
                <label for="maxModules" class="form-label">max Modules </label>
                <input
                  type="number"
                  id="maxModules"
                  name="maxModules"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.maxModules"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="maxSequences" class="form-label"
                  >max Sequences
                </label>
                <input
                  type="number"
                  id="maxSequences"
                  name="maxSequences"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.maxSequences"
                />
              </div>
              <div class="col-md-6">
                <label for="MaxQuestions" class="form-label"
                  >Max Questions
                </label>
                <input
                  type="number"
                  id="MaxQuestions"
                  name="MaxQuestions"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.MaxQuestions"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="maxSequences" class="form-label">Prix (Rs)</label>
                <input
                  type="number"
                  id="maxSequences"
                  name="price"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.price"
                />
              </div>
              <div class="col-md-6">
                <label for="mode" class="form-label required">Mode </label>
                <select class="form-select m-2" v-model="add_data.mode">
                  <option value="0">Gratuit</option>
                  <option value="1">Payant</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="abonnement" class="form-label required"
                  >abonnement (mois)
                </label>
                <input
                  type="number"
                  id="abonnement"
                  name="abonnement"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.abonnement"
                />
              </div>
              <div class="col-md-6">
                <label for="isActive" class="form-label required"
                  >Status
                </label>
                <select class="form-select m-2" v-model="add_data.isActive">
                  <option value="1">Activer</option>
                  <option value="0">Desactiver</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="abonnement" class="form-label">Module </label>
                <Multiselect
                  v-model="selectModule"
                  mode="tags"
                  :object="true"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="Allmodules"
                  label="name"
                  trackBy="name"
                />
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="abonnement" class="form-label">Quizz </label>
                <Multiselect
                  v-model="selectQuizz"
                  mode="tags"
                  :object="true"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="AllQuizz"
                  label="name"
                  trackBy="name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                  <label for="description" class="form-label required">Descritpion</label>
                  <editor
                    v-model="add_data.description"
                    initialValue=""
                    api-key='v9nz9dfppblz33d6xgxd6dz9tc6ulwqxsqhflxfcj4s2k8hs'
                    :init="{
                      height: 150,
                      //plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable'
                      plugins: 'advlist lists emoticons table',
                      menubar: 'file edit view insert format tools table tc help',
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                      
                    }"
                  >
              </editor>
                <!--  <textarea
                      v-model="add_data.description"
                      class="form-control form-control-solid mb-8"
                      rows="5"
                  >                    
                  </textarea>
                  -->
              </div>
            </div>
            <!-- <div class="row"  v-for="(languedesc ,index) in add_data.listeDescription" :key="index" style="margin-top:1%">
            
              <div class="col-md-12 padding_left_none">
                <label for="description" class="form-label required">Description ({{languedesc.name}})</label>
                <textarea
                      v-model="languedesc.valueD"
                      class="form-control form-control-solid mb-8"
                      rows="5"
                  >                    
                  </textarea>
              </div>           
            </div> -->
          </form>
        </div>
        <!--End Body modal add user-->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- show liste module -->
  <div class="modal fade" tabindex="-1" id="kt_modal_Listemodules">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Module</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataModule()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Nom</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listeModules" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                    <input type="number" v-model="item.order" min="0">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataModule()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="save_order_module()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>



  <!-- show liste quizz -->
  <div class="modal fade" tabindex="-1" id="kt_modal_Listequizz">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Quizz</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataQuizz()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Nom</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listeQuizz" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                    <input type="number" v-model="item.order" min="0">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataQuizz()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="save_order_quizz()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";
import loader from "./utility/loader.vue";

export default {
  components: {
    Multiselect,
    editor: Editor,
    loader
  },
  data() {
    return {
      data: {},
      add_data: {
        isActive: 1,
        mode: 1,
        id: 0,
        // listeName:[],
        // listeDescription:[]
      },      
      formMode: "ajouter",
      selectModule: [],
      Allmodules: {},
      listeModules: {},
      listeQuizz: {},
      AllQuizz:{},
      selectQuizz:[],
      showloader: false,
      orderStatus: "asc",
      ListeLangue:{},
    };
  },
  methods: {
    getListeLangue(){
      ApiService.setHeader();
      ApiService.get("traduction/langue/All")
        .then((response) => {
          if(response.data){
            this.ListeLangue = response.data;
            //this.traitement_langue();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async traitement_langue(){
      // await this.ListeLangue.forEach((item, index) => {
      //   this.ListeLangue[index]["value"] = "";
      // });
      // const data_tmp = JSON.parse(JSON.stringify(this.ListeLangue));
      // this.add_data.listeName = this.ListeLangue;
      // this.add_data.listeDescription = this.ListeLangue;
    },
    sortNumerique(index) {
      if (this.orderStatus === 'asc') {          
          this.data = this.data.sort(function (a, b) {
              return a[index] - b[index]
          });
          this.orderStatus = 'desc';
      } else if (this.orderStatus === 'desc') {          
          this.data = this.data.sort(function (a, b) {
              return b[index] - a[index]
          });
          this.orderStatus = 'asc';
      };
    },
    sort(index) {
      if (this.orderStatus === 'asc') {
          this.orderStatus = 'desc';
          this.data = this.data.sort((a, b) => {
            return b[index].localeCompare(a[index]);
          });
      } else if (this.orderStatus === 'desc') {
          this.orderStatus = 'asc';
          this.data = this.data.sort((a, b) => {
            return a[index].localeCompare(b[index]);
          });
      };
    },
    date_format(value){
      return moment(String(value)).format('DD/MM/YYYY hh:mm')
    },
    save_order_quizz(){
      this.showloader = true;
          const data = {};
          data["liste"] = this.listeQuizz;
          data["table"] = "quizz";

          ApiService.setHeader();
          ApiService.post("tests/order/InsertOrUpdate", data)
            .then((response) => {  
              this.showloader = false;              
                  this.alertSucess();
            })
            .catch(({ response }) => {
              console.log(response);
            });
    },
    save_order_module(){  
      this.showloader = true;
          const data = {};
          data["liste"] = this.listeModules;
          data["table"] = "modules";

          ApiService.setHeader();
          ApiService.post("tests/order/InsertOrUpdate", data)
            .then((response) => {   
              this.showloader = false;             
                  this.alertSucess();
            })
            .catch(({ response }) => {
              console.log(response);
            });
    },
    getListeQuizz() {
      ApiService.setHeader();
      ApiService.get("tests/quizz")
        .then(async (result) => {
          this.AllQuizz = await result.data;
          this.traitement_quizz();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    traitement_quizz() {
      this.AllQuizz.forEach((item, index) => {
        this.AllQuizz[index]["value"] = item.id;
      });
    },
    getModule() {
      ApiService.setHeader();
      ApiService.get("formation/moduleAll")
        .then(async (result) => {
          this.Allmodules = await result.data;
          this.traitement_module();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    traitement_module() {
      this.Allmodules.forEach((item, index) => {
        this.Allmodules[index]["value"] = item.id;
      });
    },
    getListeOffre() {
      this.showloader = true;
      ApiService.setHeader();
      ApiService.get("formation/offre")
        .then(async(result) => {
          this.data = await result.data;
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      this.showloader = true;
      const data_select_tmp = [];
      this.selectModule.forEach(async (item, index) => {
        await data_select_tmp.push(item.id);
      });

      const data_select_q = [];
      this.selectQuizz.forEach(async (item, index) => {
        await data_select_q.push(item.id);
      });

      // traitement traduction name
      const data_langue_Name = [];
      this.add_data.listeName.forEach(async (item, index) => {
        if(item.valueN)
          await data_langue_Name.push({
              id:item.id,
              valueN:item.valueN
          });
      });

      // traitement traduction name
      const data_langue_Description = [];
      this.add_data.listeDescription.forEach(async (item, index) => {
        if(item.valueD)
          await data_langue_Description.push({
              id:item.id,
              valueD:item.valueD
          });
      });

      this.add_data["listeLangueName"] = data_langue_Name;
      this.add_data["listeLangueDescription"] = data_langue_Description;
      this.add_data["listeIdModule"] = data_select_tmp;
      this.add_data["listeIdQuizz"] = data_select_q;
      this.add_data["formMode"] = this.formMode;

      ApiService.setHeader();
      ApiService.post("formation/offre/InsertOrUpdate", this.add_data)
        .then((response) => {
          this.showloader = false;
          this.alertSucess('Enregistrer avec succes!','success');
        })
        .catch(({ response }) => {
          console.log(response.request.response);
          this.alertSucess(response.request.response,'error')
        });
    },
    alertSucess(msg,status) {
      Swal.fire({
        text: msg,
        icon: status,
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        if(status == 'success'){
            location.reload();
        }        
      });
    },
    async maj(item, val) {
      this.formMode = val;
      this.selectModule = [];
      this.selectQuizz=[];
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;
        this.traitement_select_module(item.modules);
        this.traitement_select_quizz(item.offrequizz);
        this.traitement_langue_maj(item.traduction);
      } else {
        this.add_data = {
          isActive: 1,
          mode: 1,
          id: 0,
          listeName:[],
          listeDescription:[]
        };
        this.traitement_langue();
      }
    }, 
    traitement_langue_maj(data){
      this.ListeLangue.forEach((item, index) => {        
         data.forEach(async (item1, index1) => {
              if(item1.langue_id == item.id && item1.category == 'name'){
                  this.ListeLangue[index]["valueN"] = item1.value;
              }
              if(item1.langue_id == item.id && item1.category == 'desc'){
                  this.ListeLangue[index]["valueD"] = item1.value;
              }

          });
      });

      // ListeLangue.forEach(async (item, index) => {
      //   if(item.category == 'name'){
      //     this.add_data.listeName.push(
      //       {
      //         id:item.id,
      //         valueN:item.value,
      //         name:item.langue[0].name
      //       }
      //     )
      //   }
       
      // });
      this.add_data["listeName"]=this.ListeLangue;
      this.add_data["listeDescription"]=this.ListeLangue;
      console.log("eto ndray e");
      console.log(this.add_data);
    },
    traitement_select_module(data) {
      data.forEach(async (item, index) => {
        data[index]["value"] = await item.id;
        this.selectModule.push(item);
      });
    },
    traitement_select_quizz(data) {
      data.forEach(async (item, index) => {
        data[index]["value"] = await item.id;
        this.selectQuizz.push(item);
      });
    },      
    resetData() {
      this.add_data = {
        isActive: 1,
        mode: 1,
        id: 0,
      };
      this.selectModule = [];
    },
    resetDataModule() {
      this.listeModules = {}; 
    },
    resetDataQuizz() {
      this.listeQuizz = {};     
    },
    async listeModule(modules) {
      this.listeModules = await modules;
    },
    async listeQuizzShow(quizz) {
      this.listeQuizz = await quizz;
    },
  },
  mounted() {
    this.getListeOffre();
    this.getModule();
    this.getListeQuizz();
    this.getListeLangue();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
